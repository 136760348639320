import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

/**
 * Mate logo fallback icon
 * @deprecated Use LogoFallback instead
 * @private This component is not for direct use
 */
export const IconMateFallback: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    dataQa="icon-mate-fallback"
    {...props}
  >
    <path d="M12.7424 7H10.5156L7.87121 11.3557L5.22684 7H3V16.8039H5.22684V11.1036L7.74595 15.2633H7.99647L10.5156 11.1036V16.8039H12.7424V7Z" fill="currentColor" />
    <path d="M18.9123 9.80112V10.4594C18.4531 9.92717 17.7711 9.60504 16.8386 9.60504C15.0154 9.60504 13.5122 11.2157 13.5122 13.3025C13.5122 15.3894 15.0154 17 16.8386 17C17.7711 17 18.4531 16.6779 18.9123 16.1457V16.8039H21V9.80112H18.9123ZM17.2561 15.0112C16.2958 15.0112 15.5999 14.3529 15.5999 13.3025C15.5999 12.2521 16.2958 11.5938 17.2561 11.5938C18.2165 11.5938 18.9123 12.2521 18.9123 13.3025C18.9123 14.3529 18.2165 15.0112 17.2561 15.0112Z" fill="currentColor" />
  </BaseIcon>
);
