import {
  professionSlug,
  str,
  oneOf,
  int,
  intOrString,
} from '@/controllers/router/type-safe-routes/parsers';
import { TopicSections } from '@/controllers/courseUserTopics/courseUserTopics.typedefs';

export const learn = {
  path: ['learn'],
  query: [professionSlug.optional('course'), str.optional('module')],
  children: {
    selectCourse: {
      path: ['select-course'],
    },
    applicationsHistory: {
      path: ['applications-history'],
    },
    application: {
      path: ['application'],
      query: [
        professionSlug.optional('courseSlug'),
        str.optional('source'),
        professionSlug.optional('profession_slug'),
      ],
      children: {
        selectedApplication: {
          path: [intOrString.optional('courseUserId')],
        },
        new: {
          path: ['new'],
        },
      },
    },
    courseTopic: {
      path: [str('moduleSlug'), str('courseTopicSlug')],
      query: [
        oneOf(...Object.values(TopicSections)).optional('section'),
        str.optional('testTaskSlug'),
        int.optional('videoId'),
      ],
    },
  },
};
