import React, { memo } from 'react';
import { cn } from '@/lib';
import { IconKnowelyLogoSquare } from '@/components/ui/icons/product/Knowely/IconKnowelyLogoSquare';
import { FCIcon } from '@/components/ui/icons/typedefs';
import styles from './KnowelyLogo.module.scss';

/**
 * Knowely logo square icon
 * @deprecated Use LogoSquare instead
 * @private This component is not for direct use
 */
export const KnowelyLogoSquare: FCIcon<{
  withHoverEffect?: boolean;
}> = memo(({
  customProps,
  className,
  ...rest
}) => (
  <IconKnowelyLogoSquare
    className={cn(className, {
      [styles.withHoverEffect]: customProps?.withHoverEffect,
    })}
    {...rest}
  />
));
