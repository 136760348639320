import {
  ApolloCache, ApolloClient, DocumentNode, Reference, TypedDocumentNode,
} from '@apollo/client';
import { Modifier } from '@apollo/client/cache';
import uniqBy from 'lodash/uniqBy';
import { ChatFilter } from '@/controllers/graphql/generated';
import {
  ChatGroups,
  ChatsQueryVariables,
  ChildMessageTypes,
  MessageFragmentName,
  MessageType,
} from '@/components/platform/Chat/chat.typedefs';
import {
  UserMentionsDocument,
  UserMentionsQuery,
} from '@/components/platform/Chat/graphql/generated/userMentions.query.generated';
import {
  PinnedMessagesDocument,
  PinnedMessagesQuery,
  PinnedMessagesQueryVariables,
} from '@/components/platform/Chat/graphql/generated/chatPinnedMessages.query.generated';
import {
  ChatMessageByIdDocument,
  ChatMessageByIdQuery,
} from '@/components/platform/Chat/graphql/generated/chatMessageById.query.generated';
import {
  AbstractMessageFragment,
  AbstractMessageFragmentDoc,
} from '@/components/platform/Chat/graphql/generated/abstractMessage.fragment.generated';
import {
  ChatGroupsDocument,
  ChatGroupsQuery,
} from '@/components/platform/Chat/graphql/generated/chatGroups.query.generated';
import { ChatsGroupQuery } from '@/components/platform/Chat/graphql/generated/chatsGroup.query.generated';
import {
  CHAT_GROUPS_QUERY_KEYS,
  INITIAL_QUERY_VARIABLES,
  SAVED_MESSAGES_LIMIT,
  SUBSCRIBED_THREADS_LIMIT,
} from '@/components/platform/Chat/chat.constants';
import { resolveChatGroupFromChat, resolveChatGroupFromQueryVariables } from '@/components/platform/Chat/chat.helpers';
import {
  ChatWithAuthParticipantMinimalFragment,
  ChatWithAuthParticipantMinimalFragmentDoc,
} from '@/components/platform/Chat/graphql/generated/chatWithAuthParticipantMinimal.generated';
import { getGraphqlFieldArgs } from '@/components/platform/LmsEditor/LmsEditor.helpers';
import {
  SubscribedThreadsDocument,
  SubscribedThreadsQuery,
} from '@/components/platform/Chat/graphql/generated/subscribedThreads.query.generated';
import { ThreadFragmentDoc } from '@/components/platform/Chat/graphql/generated/thread.fragment.generated';
import { MessageFragmentDoc } from '@/components/platform/Chat/graphql/generated/message.fragment.generated';
import { OpenQuestionFragmentDoc } from '@/components/platform/Chat/graphql/generated/openQuestion.fragment.generated';
import {
  OpenQuestionAnswerFragmentDoc,
} from '@/components/platform/Chat/graphql/generated/openQuestionAnswer.fragment.generated';
import { PollFragmentDoc } from '@/components/platform/Chat/graphql/generated/poll.fragment.generated';
import {
  SavedMessagesDocument,
  SavedMessagesQuery,
  SavedMessagesQueryVariables,
} from './graphql/generated/savedMessages.query.generated';

export interface MessageFields {
  message: ChildMessageTypes;
  fragment: DocumentNode | TypedDocumentNode;
  fragmentName: string;
}

export interface ThreadFields {
  type: string;
  fieldToUpdate: string;
  shouldIncrementRepliesCount?: boolean;
}

interface UpdateParticipantLastActionTimeFields {
  store: ApolloCache<any>;
  participantId?: number;
  lastActionTime: number;
  chatId: number;
}

export const getChatFromCache = (
  store: ApolloCache<any>,
  chatId: number,
) => {
  const groupChat = store.readFragment<
    ChatWithAuthParticipantMinimalFragment
  >({
    id: store.identify({
      __typename: 'GroupChat',
      id: chatId,
    }),
    fragment: ChatWithAuthParticipantMinimalFragmentDoc,
    fragmentName: 'ChatWithAuthParticipantMinimal',
  });

  const privateChat = store.readFragment<
    ChatWithAuthParticipantMinimalFragment
  >({
    id: store.identify({
      __typename: 'PrivateChat',
      id: chatId,
    }),
    fragment: ChatWithAuthParticipantMinimalFragmentDoc,
    fragmentName: 'ChatWithAuthParticipantMinimal',
  });

  return groupChat || privateChat;
};

export const addMessageToThread = <Mutation>(
  store: ApolloCache<Mutation>,
  threadFields: ThreadFields,
  messageFields: MessageFields,
) => {
  const { message, fragment, fragmentName } = messageFields;
  const { type, fieldToUpdate, shouldIncrementRepliesCount } = threadFields;

  const threadCachedId = store.identify({
    __typename: type,
    id: message.threadId,
  });

  const fieldToUpdateModifier: Modifier<Reference[]> = (
    existingMessageRefs,
    { readField },
  ) => {
    const receivedMessageRef = store.writeFragment({
      data: message,
      fragment,
      fragmentName,
    });

    const alreadyExist = existingMessageRefs.some((ref) => (
      readField('id', ref) === message.id
    ));

    if (alreadyExist || !receivedMessageRef) {
      return existingMessageRefs;
    }

    return [...existingMessageRefs, receivedMessageRef];
  };

  store.modify({
    id: threadCachedId,
    fields: {
      [fieldToUpdate]: fieldToUpdateModifier,
      ...(type === 'Thread' && {
        lastSentMessageTime: () => message.createdAt,
      }),
      ...(type === 'Thread' && shouldIncrementRepliesCount && {
        repliesCount: (currentCount) => (currentCount || 0) + 1,
      }),
    },
  });
};

const updateSavedMessageList = ({ cache, savedMessages }: {
  cache: ApolloCache<any>;
  savedMessages: AbstractMessageFragment[];
}) => {
  cache.writeQuery<SavedMessagesQuery, SavedMessagesQueryVariables>({
    query: SavedMessagesDocument,
    variables: {
      limit: SAVED_MESSAGES_LIMIT,
    },
    data: { savedMessages },
  });
};

export const addToSavedMessagesList = async (
  apolloClient: ApolloClient<any>,
  message: { id: number },
) => {
  const savedMessagesCache = apolloClient.cache.readQuery<
    SavedMessagesQuery,
    SavedMessagesQueryVariables
  >({
    query: SavedMessagesDocument,
    variables: {
      limit: SAVED_MESSAGES_LIMIT,
    },
  });

  if (!savedMessagesCache) {
    return;
  }

  const alreadyExists = savedMessagesCache.savedMessages.some(
    (savedMessage) => savedMessage.id === message.id,
  );

  if (alreadyExists) {
    return;
  }

  const messageFromCache = apolloClient.cache.readFragment<
    AbstractMessageFragment
  >({
    id: apolloClient.cache.identify(message),
    fragment: AbstractMessageFragmentDoc,
    fragmentName: MessageFragmentName.AbstractMessage,
  });

  if (messageFromCache) {
    updateSavedMessageList({
      cache: apolloClient.cache,
      savedMessages: [messageFromCache, ...savedMessagesCache.savedMessages],
    });

    return;
  }

  const receivedData = await apolloClient.query<ChatMessageByIdQuery>({
    query: ChatMessageByIdDocument,
    variables: {
      id: message.id,
    },
  });

  const loadedMessage = receivedData.data.chatMessageById;

  updateSavedMessageList({
    cache: apolloClient.cache,
    savedMessages: [loadedMessage, ...savedMessagesCache.savedMessages],
  });
};

export const removeFromSavedMessagesList = (
  cache: ApolloCache<any>,
  message: { id: number },
) => {
  const savedMessagesCache = cache.readQuery<
    SavedMessagesQuery,
    SavedMessagesQueryVariables
  >({
    query: SavedMessagesDocument,
    variables: {
      limit: SAVED_MESSAGES_LIMIT,
    },
  });

  if (!savedMessagesCache) {
    return;
  }

  const otherSavedMessages = savedMessagesCache.savedMessages.filter(
    (savedMessage) => savedMessage.id !== message.id,
  );

  updateSavedMessageList({
    cache,
    savedMessages: otherSavedMessages,
  });
};

const incrementUnreadChatMessagesQuery = (
  store: ApolloCache<any>,
) => {
  store.modify({
    fields: {
      hasUnreadChatMessages: () => true,
    },
  });
};

const incrementChatFragmentUnreadMessages = (
  store: ApolloCache<any>,
  chatId: number,
) => {
  store.modify({
    id: store.identify({
      __typename: 'GroupChat',
      id: chatId,
    }),
    fields: {
      unreadMessagesCount: (currentCount) => (currentCount || 0) + 1,
    },
  });

  store.modify({
    id: store.identify({
      __typename: 'PrivateChat',
      id: chatId,
    }),
    fields: {
      unreadMessagesCount: (currentCount) => (currentCount || 0) + 1,
    },
  });
};

export const incrementUnreadChatMessagesCount = (
  store: ApolloCache<any>,
  message: AbstractMessageFragment,
) => {
  const { chatId, isOwn } = message;

  if (isOwn) {
    return;
  }

  incrementUnreadChatMessagesQuery(store);
  incrementChatFragmentUnreadMessages(store, chatId);
};

export const incrementSavedMessagesCount = (store: ApolloCache<any>) => {
  store.modify({
    fields: {
      savedMessagesCount: (currentCount) => (
        (currentCount || 0) + 1
      ),
    },
  });
};

export const decrementChatFragmentUnreadMessages = (
  store: ApolloCache<any>,
  chatId: number,
) => {
  store.modify({
    id: store.identify({
      __typename: 'GroupChat',
      id: chatId,
    }),
    fields: {
      unreadMessagesCount: (currentCount) => (
        currentCount > 0
          ? currentCount - 1
          : 0
      ),
    },
  });

  store.modify({
    id: store.identify({
      __typename: 'PrivateChat',
      id: chatId,
    }),
    fields: {
      unreadMessagesCount: (currentCount) => (
        currentCount > 0
          ? currentCount - 1
          : 0
      ),
    },
  });
};

export const decrementSavedMessagesCount = (store: ApolloCache<any>) => {
  store.modify({
    fields: {
      savedMessagesCount: (currentCount: number) => (
        currentCount > 0
          ? currentCount - 1
          : 0
      ),
    },
  });
};

export const resetUnreadChatMessagesCount = ({
  store,
  chatId,
  hasUnreadChatMessages,
}: {
  store: ApolloCache<any>;
  chatId: number;
  hasUnreadChatMessages: boolean;
}) => {
  store.modify({
    fields: {
      hasUnreadChatMessages: () => hasUnreadChatMessages,
    },
  });

  store.modify({
    id: store.identify({
      __typename: 'GroupChat',
      id: chatId,
    }),
    fields: {
      unreadMessagesCount: () => 0,
    },
  });

  store.modify({
    id: store.identify({
      __typename: 'PrivateChat',
      id: chatId,
    }),
    fields: {
      unreadMessagesCount: () => 0,
    },
  });
};

const updatePinnedMessageList = ({ cache, chatId, pinnedMessages }: {
  cache: ApolloCache<any>;
  chatId: number;
  pinnedMessages: AbstractMessageFragment[];
}) => {
  cache.writeQuery<PinnedMessagesQuery, PinnedMessagesQueryVariables>({
    query: PinnedMessagesDocument,
    variables: { chatId },
    data: { pinnedMessages },
  });
};

export const addToPinnedMessagesList = async (
  apolloClient: ApolloClient<any>,
  message: {
    id: number;
    chatId: number;
    pinnedBy?: number | null;
  },
) => {
  const pinnedMessagesCache = apolloClient.cache.readQuery<
    PinnedMessagesQuery,
    PinnedMessagesQueryVariables
  >({
    query: PinnedMessagesDocument,
    variables: {
      chatId: message.chatId,
    },
  });

  if (!pinnedMessagesCache) {
    return;
  }

  const alreadyExists = pinnedMessagesCache.pinnedMessages.some(
    (pinnedMessage) => pinnedMessage.id === message.id,
  );

  if (alreadyExists) {
    return;
  }

  const messageFromCache = apolloClient.cache.readFragment<
    AbstractMessageFragment
  >({
    id: apolloClient.cache.identify(message),
    fragment: AbstractMessageFragmentDoc,
    fragmentName: MessageFragmentName.AbstractMessage,
  });

  if (messageFromCache) {
    updatePinnedMessageList({
      cache: apolloClient.cache,
      chatId: message.chatId,
      pinnedMessages: [...pinnedMessagesCache.pinnedMessages, messageFromCache],
    });

    return;
  }

  const receivedData = await apolloClient.query<ChatMessageByIdQuery>({
    query: ChatMessageByIdDocument,
    variables: {
      id: message.id,
    },
  });

  const loadedMessage = receivedData.data.chatMessageById;

  updatePinnedMessageList({
    cache: apolloClient.cache,
    chatId: message.chatId,
    pinnedMessages: [...pinnedMessagesCache.pinnedMessages, loadedMessage],
  });
};

export const removeFromPinnedMessagesList = (
  cache: ApolloCache<any>,
  message: { id: number; chatId: number },
) => {
  const pinnedMessagesCache = cache.readQuery<
    PinnedMessagesQuery,
    PinnedMessagesQueryVariables
  >({
    query: PinnedMessagesDocument,
    variables: {
      chatId: message.chatId,
    },
  });

  if (!pinnedMessagesCache) {
    return;
  }

  const otherPinnedMessages = pinnedMessagesCache.pinnedMessages.filter(
    (pinnedMessage) => pinnedMessage.id !== message.id,
  );

  updatePinnedMessageList({
    cache,
    chatId: message.chatId,
    pinnedMessages: otherPinnedMessages,
  });
};

export const incrementUnreadThreadRepliesQuery = (
  store: ApolloCache<any>,
) => {
  store.modify({
    fields: {
      unreadThreadRepliesCount: (currentCount) => (
        (currentCount || 0) + 1
      ),
    },
  });
};

export const resetUnreadThreadReplies = (
  store: ApolloCache<any>,
  updatedRepliesCount: number,
) => {
  store.modify({
    fields: {
      unreadThreadRepliesCount: () => updatedRepliesCount,
    },
  });
};

export const decrementUnreadThreadReplies = (
  store: ApolloCache<any>,
) => {
  store.modify({
    fields: {
      unreadThreadRepliesCount: (currentCount: number) => (
        currentCount > 0
          ? currentCount - 1
          : 0
      ),
    },
  });
};

export const decreaseUnreadThreadRepliesCount = (
  store: ApolloCache<any>,
  count: number,
) => {
  store.modify({
    fields: {
      unreadThreadRepliesCount: (currentCount: number) => (
        currentCount - count > 0
          ? currentCount - count
          : 0
      ),
    },
  });
};

interface DeleteMessageFromThreadOptions {
  store: ApolloCache<any>;
  parentType: MessageType;
  message: { id: number; threadId?: number | null };
  shouldDecrementRepliesCount?: boolean;
}

export const deleteMessageFromThread = (
  options: DeleteMessageFromThreadOptions,
) => {
  const {
    store,
    parentType,
    message,
    shouldDecrementRepliesCount,
  } = options;

  const threadApolloId = store.identify({
    __typename: parentType,
    id: message.threadId,
  });

  const childType = parentType === MessageType.OpenQuestion
    ? 'answers'
    : 'messages';

  const childTypeModifier: Modifier<Reference[]> = (
    existingMessagesRefs,
    { readField },
  ) => (
    existingMessagesRefs.filter((messageRef) => (
      readField('id', messageRef) !== message.id
    ))
  );

  store.modify({
    id: threadApolloId,
    fields: {
      [childType]: childTypeModifier,
      ...(parentType === MessageType.Thread && shouldDecrementRepliesCount && {
        repliesCount: (currentCount) => (
          currentCount > 0
            ? currentCount - 1
            : 0
        ),
      }),
    },
  });
};

export const filterReadChatMentions = (
  store: ApolloCache<any>,
  chatId: number,
) => {
  const mentionsCache = store.readQuery<UserMentionsQuery>({
    query: UserMentionsDocument,
  });

  if (!mentionsCache) {
    return;
  }

  const filteredMentions = mentionsCache.userMentions.filter(
    (mention) => (
      mention.chatId !== chatId || mention.threadId
    ),
  );

  store.writeQuery<UserMentionsQuery>({
    query: UserMentionsDocument,
    data: {
      userMentions: filteredMentions,
    },
  });
};

export const filterReadThreadMentions = (
  store: ApolloCache<any>,
  threadId: number,
) => {
  const mentionsCache = store.readQuery<UserMentionsQuery>({
    query: UserMentionsDocument,
  });

  if (!mentionsCache) {
    return;
  }

  const filteredMentions = mentionsCache.userMentions.filter(
    (mention) => (
      mention.threadId !== threadId
    ),
  );

  store.writeQuery<UserMentionsQuery>({
    query: UserMentionsDocument,
    data: {
      userMentions: filteredMentions,
    },
  });
};

export const updateChatLastActionTime = (
  store: ApolloCache<any>,
  chatId: number,
  lastActionTime: number,
) => {
  store.modify({
    id: store.identify({
      __typename: 'GroupChat',
      id: chatId,
    }),
    fields: {
      lastActionTime: () => lastActionTime,
    },
  });

  store.modify({
    id: store.identify({
      __typename: 'PrivateChat',
      id: chatId,
    }),
    fields: {
      lastActionTime: () => lastActionTime,
    },
  });
};

export const updateParticipantLastActionTime = ({
  store,
  participantId,
  lastActionTime,
  chatId,
}: UpdateParticipantLastActionTimeFields) => {
  store.modify({
    id: store.identify({
      __typename: 'Participant',
      id: participantId,
    }),
    fields: {
      lastActionTime: () => lastActionTime,
    },
  });

  filterReadChatMentions(store, chatId);
};

export const updateThreadLastActionTime = <Fragment>(
  store: ApolloCache<Fragment>,
  parentType: MessageType,
  parentId: number,
  actionTime: any,
) => {
  store.modify({
    id: store.identify({
      __typename: parentType,
      id: parentId,
    }),
    fields: {
      lastActionTime: () => actionTime,
      lastReadTime: () => actionTime,
    },
  });

  filterReadThreadMentions(store, parentId);
};

export const addChatToArchived = <Query>(
  chatRef: Reference,
  cache: ApolloCache<Query>,
) => {
  cache.modify({
    fields: {
      chatsByFilter(existingChatsRefs, { storeFieldName }) {
        if (!storeFieldName.includes(ChatFilter.Archived)) {
          return existingChatsRefs.filter((currentRef: Reference) => (
            currentRef.__ref !== chatRef.__ref
          ));
        }

        return [...existingChatsRefs, chatRef];
      },
    },
  });
};

export const removeChatFromArchived = <Query>(
  chatRef: Reference,
  cache: ApolloCache<Query>,
  shouldAddToFavorite?: boolean,
) => {
  cache.modify({
    fields: {
      chatsByFilter(existingChatsRefs, { storeFieldName }) {
        if (storeFieldName.includes(ChatFilter.Archived)) {
          return existingChatsRefs.filter((currentRef: Reference) => (
            currentRef.__ref !== chatRef.__ref
          ));
        }

        if (
          storeFieldName.includes(ChatFilter.Favorite)
            && !shouldAddToFavorite
        ) {
          return existingChatsRefs;
        }

        return [...existingChatsRefs, chatRef];
      },
    },
  });
};

export const removeThreadFromUnreadSubscribedThreads = (
  cache: ApolloCache<any>,
  threadId: number,
) => {
  const cachedQuery = cache.readQuery<SubscribedThreadsQuery>({
    query: SubscribedThreadsDocument,
    variables: {
      limit: SUBSCRIBED_THREADS_LIMIT,
      unreadOnly: true,
    },
  });

  if (!cachedQuery) {
    return;
  }

  cache.writeQuery({
    query: SubscribedThreadsDocument,
    variables: {
      limit: SUBSCRIBED_THREADS_LIMIT,
      unreadOnly: true,
    },
    data: {
      subscribedThreads:
        cachedQuery.subscribedThreads.filter((thread) => (
          thread.id !== threadId
        )),
    },
  });
};

export const messageFragmentByType = (type?: string): DocumentNode | null => {
  switch (type) {
    case MessageType.Message:
      return MessageFragmentDoc;
    case MessageType.Thread:
      return ThreadFragmentDoc;
    case MessageType.OpenQuestion:
      return OpenQuestionFragmentDoc;
    case MessageType.OpenQuestionAnswer:
      return OpenQuestionAnswerFragmentDoc;
    case MessageType.Poll:
      return PollFragmentDoc;
    default:
      return null;
  }
};

export const messageFragmentNameByType = (type?: string): string | null => {
  switch (type) {
    case MessageType.Message:
      return MessageFragmentName.Message;
    case MessageType.Thread:
      return MessageFragmentName.Thread;
    case MessageType.OpenQuestion:
      return MessageFragmentName.OpenQuestion;
    case MessageType.OpenQuestionAnswer:
      return MessageFragmentName.OpenQuestionAnswer;
    case MessageType.Poll:
      return MessageFragmentName.Poll;
    default:
      return null;
  }
};

export const addChatsToGroup = (
  cache: ApolloCache<any> | ApolloClient<any>,
  group: ChatGroups,
  queryResult: ChatsGroupQuery,
) => {
  const key = CHAT_GROUPS_QUERY_KEYS[group];

  const existingChats = cache.readQuery<ChatGroupsQuery>({
    query: ChatGroupsDocument,
    variables: INITIAL_QUERY_VARIABLES,
  });
  const existingChatGroup = existingChats?.[key] || [];

  const uniqueChats = uniqBy([
    ...existingChatGroup,
    ...queryResult.chats,
  ], ({ id }) => id);

  cache.writeQuery({
    query: ChatGroupsDocument,
    data: {
      ...existingChats,
      [key]: uniqueChats,
    },
    variables: INITIAL_QUERY_VARIABLES,
  });
};

export const addChatRefToGroup = (
  group: ChatGroups,
  chat: ChatWithAuthParticipantMinimalFragment,
) => (
  apollo: ApolloClient<any>,
) => {
  const {
    id: chatId,
  } = chat;
  const key = CHAT_GROUPS_QUERY_KEYS[group];

  const existingChats = apollo.readQuery<ChatGroupsQuery>({
    query: ChatGroupsDocument,
    variables: INITIAL_QUERY_VARIABLES,
  });

  if (!existingChats) {
    return;
  }

  const existingChat = existingChats[key]
    .find((currentChat) => currentChat.id === chatId);

  if (existingChat) {
    return;
  }

  apollo.cache.modify({
    fields: {
      chats(cachedChats, { storeFieldName, fieldName }) {
        const variables = getGraphqlFieldArgs<ChatsQueryVariables>(
          storeFieldName,
          fieldName,
        );
        const groupFromFilters = resolveChatGroupFromQueryVariables(variables);

        if (groupFromFilters !== group) {
          return cachedChats;
        }

        const loadedChatRef = apollo.cache.writeFragment({
          data: chat,
          fragment: ChatWithAuthParticipantMinimalFragmentDoc,
          fragmentName: 'ChatWithAuthParticipantMinimal',
        });

        return [loadedChatRef, ...cachedChats];
      },
    },
  });
};

export const ensureChatInGroup = (
  apollo: ApolloClient<any>,
  chat: ChatWithAuthParticipantMinimalFragment,
) => {
  const group = resolveChatGroupFromChat(chat);

  addChatRefToGroup(group, chat)(apollo);
};
