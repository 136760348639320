import { HOUR } from '@/constants';

const getMsBeetweenTwoDates = (
  startDate: Date,
  endDate: Date,
): number => (
  endDate.valueOf() - startDate.valueOf()
);

export const getHoursBetweenTwoDates = (
  startDate: Date,
  endDate: Date,
): number => {
  const msBetween = getMsBeetweenTwoDates(startDate, endDate);

  return Math.floor(Math.abs(msBetween) / HOUR);
};
