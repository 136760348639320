import {
  bool,
  int,
  oneOf,
  str,
} from 'typesafe-routes';
import { ChatInfoTab } from '@/components/platform/Chat/chat.typedefs';

export const chats = {
  path: ['chats'],
  children: {
    chat: {
      path: [str.optional('chatId')],
      query: [
        int.optional('selectedMessageId'),
        int.optional('threadId'),
        bool.optional('fullScreen'),
        bool.optional('isParticipantsModalOpen'),
      ],
      children: {
        info: {
          path: ['info', oneOf(...Object.values(ChatInfoTab)).optional('tab')],
        },
        pinned: {
          path: ['pinned'],
        },
        permissions: {
          path: ['permissions'],
        },
      },
    },
    info: {
      path: ['info'],
    },
    threads: {
      path: ['threads'],
      children: {
        success: {
          path: ['success'],
        },
        support: {
          path: ['support'],
        },
      },
    },
    search: {
      path: ['search'],
    },
    saved: {
      path: ['saved'],
    },
    automatedMessages: {
      path: ['automated-messages'],
      children: {
        new: {
          path: ['new'],
        },
      },
    },
  },
};
