import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconMateMonochrome: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    className={className}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.1571 13.1633C15.1571 13.9286 15.6707 14.4082 16.3795 14.4082C17.0883 14.4082 17.602 13.9286 17.602 13.1633C17.602 12.398 17.0883 11.9184 16.3795 11.9184C15.6707 11.9184 15.1571 12.398 15.1571 13.1633Z"
      fill="white"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.06054 2.80805C0.5 3.90817 0.5 5.3483 0.5 8.22857V15.7714C0.5 18.6517 0.5 20.0918 1.06054 21.192C1.5536 22.1596 2.34036 22.9464 3.30805 23.4395C4.40817 24 5.8483 24 8.72857 24H16.2714C19.1517 24 20.5918 24 21.692 23.4395C22.6596 22.9464 23.4464 22.1596 23.9395 21.192C24.5 20.0918 24.5 18.6517 24.5 15.7714V8.22857C24.5 5.3483 24.5 3.90817 23.9395 2.80805C23.4464 1.84036 22.6596 1.0536 21.692 0.560538C20.5918 0 19.1517 0 16.2714 0H8.72857C5.8483 0 4.40817 0 3.30805 0.560538C2.34036 1.0536 1.5536 1.84036 1.06054 2.80805ZM11.4044 8.57143H13.048V15.7143H11.4044V11.5612L9.54502 14.5918H9.36011L7.50076 11.5612V15.7143H5.85714V8.57143H7.50076L9.45256 11.7449L11.4044 8.57143ZM17.602 11.0918V10.6122H19.1429V15.7143H17.602V15.2347C17.263 15.6224 16.7596 15.8571 16.0713 15.8571C14.7256 15.8571 13.6162 14.6837 13.6162 13.1633C13.6162 11.6429 14.7256 10.4694 16.0713 10.4694C16.7596 10.4694 17.263 10.7041 17.602 11.0918Z"
      fill="white"
    />
  </BaseIcon>
);
