import { isBrowser } from '@/middleware/helpers/isBrowser';
import { addToSavedMessagesList, incrementSavedMessagesCount } from '@/components/platform/Chat/store.helpers';
import {
  useChatMessageSavedSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessageSaved.subscription.generated';

export const useChatMessageSaved = () => {
  useChatMessageSavedSubscription({
    skip: !isBrowser,
    onData: ({
      client,
      data: { data },
    }) => {
      const { cache } = client;

      const savedMessage = data?.chatMessageSaved;

      if (!savedMessage) {
        return;
      }

      addToSavedMessagesList(client, savedMessage);
      incrementSavedMessagesCount(cache);
    },
  });
};
