import * as Sentry from '@sentry/nextjs';
import { Logger, LogLevels } from '@mate-academy/logger';
import { GraphQLError } from 'graphql';
import { logger as rootLogger } from '@/core/Logger';
import { UserBaseFragment } from '@/controllers/user/graphql/generated/UserBase.fragment.generated';

interface SetUser {
  (user?: Pick<UserBaseFragment, 'id'> | null): void;
}

interface CaptureMessage {
  (message: string, options?: {
    fields?: Record<string, any>;
    logLevel?: LogLevels;
    logger?: Logger;
  }): void;
}

interface CaptureException {
  (error: Error, options?: {
    fields?: Record<string, any>;
    logLevel?: LogLevels;
    logMessage?: string;
    logger?: Logger;
  }) : void;
}

interface WithSentryScope {
  (cb: (scope: Sentry.Scope) => void): void;
}

interface ShouldIgnoreException {
  (error: any): boolean;
}

export class ErrorHandler {
  private static instance: ErrorHandler;

  private logger: Logger;

  private CLIENT_EXCEPTION_TYPES = [
    'UNAUTHENTICATED',
    'FORBIDDEN',
    'BAD_USER_INPUT',
    'NOT_FOUND',
  ];

  private CLIENT_EXCEPTION_NAMES = [
    'ClientError',
    'SequelizeValidationError',
  ];

  private CLIENT_EXCEPTION_MESSAGES = [
    'login_not_authorized',
  ];

  constructor() {
    this.logger = rootLogger.child('ErrorHandler');
  }

  static getInstance() {
    if (!ErrorHandler.instance) {
      ErrorHandler.instance = new ErrorHandler();
    }

    return ErrorHandler.instance;
  }

  setUser: SetUser = (user) => {
    this.logger.setUserId(user?.id ?? null);
    Sentry.setUser(
      user
        ? {
          id: `${user.id}`,
        }
        : null,
    );
  };

  private logError(message: string, options: {
    logLevel?: LogLevels;
    fields?: Record<string, any>;
    logger?: Logger;
  } = {}) {
    const {
      fields,
      logLevel = LogLevels.Warning,
      logger = this.logger,
    } = options;

    switch (logLevel) {
      case LogLevels.Error: {
        logger.error(message, { fields });
        break;
      }

      case LogLevels.Warning: {
        logger.warning(message, { fields });
        break;
      }

      default: {
        logger.info(message, { fields });
      }
    }
  }

  captureMessage: CaptureMessage = (message, options = {}) => {
    const { fields, logLevel = LogLevels.Warning, logger } = options;

    Sentry.captureMessage(message, fields);

    this.logError(message, { logLevel, fields, logger });
  };

  captureException: CaptureException = (error, options = {}) => {
    if (this.shouldIgnoreException(error)) {
      return;
    }

    const { fields, logLevel = LogLevels.Warning, logger } = options;

    const message = options.logMessage
      ? `${options.logMessage}: ${error.message}`
      : error.message;

    Sentry.captureException(error, fields);

    this.logError(message, { logLevel, fields, logger });
  };

  withSentryScope: WithSentryScope = (cb) => Sentry.withScope(cb);

  shouldIgnoreException: ShouldIgnoreException = (error: any) => {
    if (error?.graphQLErrors?.length > 0) {
      return this.shouldIgnoreGraphQLException(error.graphQLErrors[0]);
    }

    if (error?.extensions) {
      return this.shouldIgnoreGraphQLException(error);
    }

    return false;
  };

  shouldIgnoreGraphQLException(error: GraphQLError) {
    return (
      error.extensions?.exception?.name
        && this.CLIENT_EXCEPTION_NAMES.includes(error.extensions.exception.name)
    )
      || this.CLIENT_EXCEPTION_TYPES.includes(error.extensions?.code)
      || this.CLIENT_EXCEPTION_MESSAGES.some(
        (message) => (error.message ?? '').includes(message),
      );
  }
}

export const errorHandler: {
  setUser: SetUser;
  captureMessage: CaptureMessage;
  captureException: CaptureException;
  withSentryScope: WithSentryScope;
  shouldIgnoreException: ShouldIgnoreException;
} = {
  setUser(...args) {
    return ErrorHandler.getInstance().setUser(...args);
  },
  captureMessage(...args) {
    ErrorHandler.getInstance().captureMessage(...args);
  },
  captureException(...args) {
    ErrorHandler.getInstance().captureException(...args);
  },
  withSentryScope(...args) {
    ErrorHandler.getInstance().withSentryScope(...args);
  },
  shouldIgnoreException(...args) {
    return ErrorHandler.getInstance().shouldIgnoreException(...args);
  },
};
