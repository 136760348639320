import getConfig from 'next/config';
import cookie from 'cookie';
import * as amplitude from '@amplitude/analytics-browser';
import {
  GoogleAnalyticsClient,
} from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';
import { Router } from '@/middleware/i18n';
import { DOMAIN_TO_KEY_MAP } from '@/controllers/amplitude/constants';
import { getDeviceType } from '@/lib/helpers/getDeviceType';
import {
  removeQueryParams,
} from '@/controllers/analytics/analytics.utils/removeQueryParams';
import { errorHandler } from '@/core/ErrorHandler';
import { logger as rootLogger } from '@/core/Logger';
import { i18nParseLanguageSubpath } from '@/middleware/i18n/i18n.utils';
import { AuthUserFragment } from '@/controllers/user/graphql/generated/AuthUser.fragment.generated';

const logger = rootLogger.child('amplitude');

export const setAmplitudeUserId = (userId: string): void => {
  amplitude.setUserId(userId);
};

export const setAmplitudeUserProperties = (
  properties: Record<string, any>,
): void => {
  const userProperties = new amplitude.Identify();

  Object.entries(properties).forEach(([key, value]) => {
    userProperties.set(key, value);
  });

  amplitude.identify(userProperties);
};

export const sendAmplitudeData = (
  type: string,
  data: {[key: string]: any} = {},
): void => {
  try {
    const device = getDeviceType();

    amplitude.track(
      type,
      {
      // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
        route: window.location.pathname,
        page: removeQueryParams(Router.asPath),
        locale: Router.locale,
        device,
        ...data,
      },
    );
  } catch (error) {
    errorHandler.captureException(error as Error, {
      logger: logger.child('sendAmplitudeData'),
    });
  }
};

const getAmplitudeApiKey = (subDomain?: string): string => {
  const { publicRuntimeConfig = {} } = getConfig() || {};

  const {
    AMPLITUDE_API_KEY,
  } = publicRuntimeConfig;

  if (!subDomain) {
    return AMPLITUDE_API_KEY;
  }

  const key = DOMAIN_TO_KEY_MAP[subDomain];

  return key || AMPLITUDE_API_KEY;
};

export const initAmplitude = (
  authUser: AuthUserFragment | null,
  options: {
    subDomain?: string;
    deviceId?: string;
  },
): void => {
  const { subDomain, deviceId } = options;
  const { publicRuntimeConfig = {} } = getConfig() || {};
  const {
    API_HOST_PUBLIC,
  } = publicRuntimeConfig;

  const key = getAmplitudeApiKey(subDomain);

  let shouldUseDefaultAmplitudeApiEndpoint = false;

  try {
    const cookieValue = cookie
      .parse(document.cookie)
      .should_use_default_amplitude_api_endpoint;

    if (cookieValue === 'true') {
      shouldUseDefaultAmplitudeApiEndpoint = true;
    }
  } catch {
    // do nothing
  }

  if (shouldUseDefaultAmplitudeApiEndpoint) {
    // eslint-disable-next-line no-console
    console.log('📈📈📈 Starting amplitude with default api. Enjoy debugging');
  }

  amplitude.init(key, authUser?.amplitudeId, {
    deviceId,
    defaultTracking: {
      attribution: false,
      pageViews: false,
      sessions: false,
      fileDownloads: false,
      formInteractions: false,
    },
    serverUrl: shouldUseDefaultAmplitudeApiEndpoint
      ? undefined // will use default endpoint
      : `https://${API_HOST_PUBLIC}/amplitude/2/httpapi`,
    identityStorage: 'localStorage',
  });

  try {
    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    window.addEventListener('pagehide', () => {
      // Set https transport to use sendBeacon API
      amplitude.setTransport('beacon');
      // Send all pending events to server
      amplitude.flush();
    });
  } catch (error) {
    // do nothing
  }

  const utmData = GoogleAnalyticsClient.getInstance().getUserSourceData();

  const { locale } = Router;

  const websiteDomain = i18nParseLanguageSubpath(locale).domain;

  setAmplitudeUserProperties({
    ...utmData,
    WebsiteDomain: authUser?.domain?.slug ?? websiteDomain,
    locale,
  });

  if (authUser) {
    setAmplitudeUserProperties({
      Email: authUser.email,
    });
    setAmplitudeUserId(authUser.amplitudeId);
  }
};
