import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconKnowelyMonochrome: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    className={className}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.69054 2.56965C0.5 4.20829 0.5 6.54579 0.5 11.2208V12.7792C0.5 17.4542 0.5 19.7917 1.69054 21.4303C2.07504 21.9596 2.54044 22.425 3.06965 22.8095C4.70829 24 7.04579 24 11.7208 24H13.2792C17.9542 24 20.2917 24 21.9303 22.8095C22.4596 22.425 22.925 21.9596 23.3095 21.4303C24.5 19.7917 24.5 17.4542 24.5 12.7792V11.2208C24.5 6.54579 24.5 4.20829 23.3095 2.56965C22.925 2.04044 22.4596 1.57504 21.9303 1.19054C20.2917 0 17.9542 0 13.2792 0H11.7208C7.04579 0 4.70829 0 3.06965 1.19054C2.54044 1.57504 2.07504 2.04044 1.69054 2.56965ZM7.66676 20.4431C6.90627 20.1843 6.49955 19.3581 6.75834 18.5976L11.5669 4.46648C11.8257 3.70598 12.652 3.29926 13.4125 3.55805C14.173 3.81684 14.5797 4.64313 14.3209 5.40363L12.4286 10.9646L15.8404 8.12147C16.4576 7.6072 17.3747 7.69058 17.889 8.30771C18.4033 8.92484 18.3199 9.84202 17.7028 10.3563L14.68 12.8752L17.7028 15.3942C18.3199 15.9085 18.4033 16.8257 17.889 17.4428C17.3747 18.0599 16.4576 18.1433 15.8404 17.629L11.4156 13.9417L9.51234 19.5347C9.25356 20.2952 8.42726 20.7019 7.66676 20.4431Z"
      fill="white"
    />
  </BaseIcon>
);
