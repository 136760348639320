import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '@/components/ui/icons/product/Knowely/IconKnowelyLogoFull.module.scss';

/**
 * Knowely logo full icon
 * @deprecated Use LogoFull instead
 * @private This component is not for direct use
 */
export const IconKnowelyLogoFull: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    {...rest}
    className={cn(className, styles.logoIcon)}
    width="263"
    height="66"
    viewBox="0 0 263 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="knowely-logo-full-title">
      <path d="M82.5 45.5475V18.4197C82.965 13.4593 89.7857 13.4595 90.4058 18.4197V31.9798C90.4058 32.0124 90.4436 32.0304 90.469 32.0099L98.6216 25.3955C102.962 22.4503 106.217 28.0308 103.427 30.511L98.6216 34.8514L103.892 43.5323C106.682 47.7177 100.172 51.9032 97.5365 47.7177L92.7541 40.004C92.7418 39.9843 92.715 39.9797 92.6969 39.9942L90.464 41.7805C90.4272 41.81 90.4058 41.8545 90.4058 41.9016V45.5475C89.7857 50.6631 82.965 50.6631 82.5 45.5475Z" />
      <path d="M107.923 45.3924V28.3407C107.923 23.7399 114.598 23.2355 115.041 27.7281C115.049 27.8093 115.117 27.8756 115.198 27.8756H115.284C115.334 27.8756 115.381 27.8514 115.41 27.8113C118.226 23.9934 124.687 23.398 128.695 26.6354C130.71 28.4956 131.795 30.2008 131.795 35.6265V45.3924C131.795 50.818 123.889 50.663 123.889 45.3924V35.6265C123.579 30.2009 116.293 30.3559 115.828 35.6265V45.3924C115.828 50.818 107.923 50.663 107.923 45.3924Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M135.515 36.8666C135.515 29.4256 141.251 24.6203 148.382 24.6203C155.512 24.6203 161.403 29.4256 161.403 36.8666C161.403 44.3075 155.667 49.4228 148.382 49.4228C141.096 49.4228 135.515 44.3075 135.515 36.8666ZM143.886 36.8666C143.806 39.617 145.63 42.2461 148.382 42.2921C151.172 42.3387 153.114 39.656 153.032 36.8666C152.953 34.1679 151.081 31.7056 148.382 31.7511C145.723 31.7958 143.963 34.2082 143.886 36.8666Z" />
      <path d="M168.069 45.7024L163.418 29.8908C161.868 24.6203 168.689 22.1403 170.549 27.5656L173.309 38.3003C173.327 38.3688 173.389 38.4167 173.459 38.4167H173.682C173.754 38.4167 173.816 38.3679 173.833 38.2986L175.664 30.8209C177.059 26.0153 183.88 26.0153 185.43 30.8209L187.262 38.2986C187.279 38.3679 187.341 38.4167 187.412 38.4167H187.635C187.706 38.4167 187.768 38.3688 187.785 38.3003L190.546 27.5656C192.096 22.4499 199.382 24.3103 197.521 29.8908L192.871 45.7024C191.321 50.6628 184.5 50.6628 183.105 45.7024L180.809 36.3645C180.792 36.2952 180.73 36.2465 180.658 36.2465H180.434C180.364 36.2465 180.302 36.294 180.284 36.3622L177.835 45.7024C176.284 50.6628 169.774 50.6628 168.069 45.7024Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M208.063 38.882H219.999C225.424 38.882 225.269 31.596 220.619 27.5659C220.619 27.5659 217.984 24.5866 212.248 24.6206C208.84 24.6407 206.627 25.3559 204.032 27.5659C200.918 30.2183 199.692 33.0086 199.692 37.0994C199.692 41.1901 200.896 44.0067 204.032 46.6328C206.785 48.9373 209.201 49.423 212.791 49.423C219.069 49.423 221.549 46.6328 221.549 46.6328C223.564 44.6173 223.564 42.1371 221.549 40.8972C220.036 39.9667 217.674 40.1218 216.183 41.4939C215.05 42.4616 214.189 43.1966 212.248 43.0672C209.458 43.0672 208.063 41.052 208.063 38.882ZM208.063 34.0763H215.813C215.813 34.0763 215.348 30.6659 212.093 30.6659C208.838 30.6659 208.063 34.0763 208.063 34.0763Z" />
      <path d="M235.5 18.4197C235.035 13.459 227.905 13.4591 227.595 18.4197V45.7024C227.905 50.6628 235.19 50.6628 235.5 45.7024V18.4197Z" />
      <path d="M245.111 47.2525L239.376 30.3558C237.205 24.9303 244.646 22.2953 246.816 27.1005L250.815 41.2491C250.834 41.3158 250.895 41.3619 250.964 41.3619H251.039C251.108 41.3619 251.17 41.3153 251.188 41.248L255.032 27.2555C256.427 22.9151 264.178 24.1552 262.686 29.7358L256.737 48.8027C255.683 52.0977 255.062 53.4683 253.637 55.0033C251.002 57.9486 247.436 59.3438 242.166 58.4136C240.151 57.9486 238.911 56.7085 238.911 55.0033C238.911 52.9164 240.461 51.4379 242.166 51.438C243.251 51.438 244.181 51.903 244.801 51.903C246.506 51.903 247.592 50.6629 247.902 48.8027C246.506 48.8027 245.421 47.8726 245.111 47.2525Z" />
    </g>
    <g id="knowely-logo-full-fill">
      <path d="M0 30.8571C0 18.0009 0 11.5728 3.27399 7.06654C4.33136 5.6112 5.6112 4.33136 7.06654 3.27399C11.5728 0 18.0009 0 30.8571 0H35.1429C47.9991 0 54.4272 0 58.9335 3.27399C60.3888 4.33136 61.6686 5.6112 62.726 7.06654C66 11.5728 66 18.0009 66 30.8571V35.1429C66 47.9991 66 54.4272 62.726 58.9335C61.6686 60.3888 60.3888 61.6686 58.9335 62.726C54.4272 66 47.9991 66 35.1429 66H30.8571C18.0009 66 11.5728 66 7.06654 62.726C5.6112 61.6686 4.33136 60.3888 3.27399 58.9335C0 54.4272 0 47.9991 0 35.1429V30.8571Z" fill="#7852F4" />
    </g>
    <g id="knowely-logo-full-text">
      <path d="M17.2104 51.1433C16.4988 53.2346 17.6172 55.507 19.7086 56.2186C21.8 56.9303 24.0723 55.8118 24.7839 53.7204L30.0178 38.3396L42.1862 48.4798C43.8833 49.8941 46.4055 49.6648 47.8198 47.9677C49.234 46.2706 49.0047 43.7483 47.3076 42.3341L38.9951 35.4069L47.3076 28.4798C49.0047 27.0656 49.234 24.5433 47.8198 22.8462C46.4055 21.1491 43.8833 20.9198 42.1862 22.3341L32.8037 30.1528L38.0076 14.86C38.7193 12.7686 37.6008 10.4963 35.5094 9.78464C33.418 9.07298 31.1457 10.1915 30.4341 12.2828L17.2104 51.1433Z" />
    </g>
  </BaseIcon>
);
