import {
  useUnreadThreadReplyDeletedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/unreadThreadReplyDeletedWS.subscription.generated';
import { decrementUnreadThreadReplies } from '@/components/platform/Chat/store.helpers';
import { isBrowser } from '@/middleware/helpers/isBrowser';

interface Params {
  skip?: boolean;
}

export const useUnreadThreadReplyDeletedWS = ({ skip }: Params = {}) => {
  useUnreadThreadReplyDeletedWsSubscription({
    skip: !isBrowser || skip,
    onData: ({
      client: { cache },
      data: { data },
    }) => {
      const deletedMessage = data?.unreadThreadReplyDeletedWS.deletedMessage;

      if (!deletedMessage) {
        return;
      }

      decrementUnreadThreadReplies(cache);
    },
  });
};
