/* eslint-disable no-restricted-imports */
import NextRouter, { useRouter as useNextRouter } from 'next/router';
import NextLink from 'next/link';
import NextI18Next from './i18n';

export const {
  appWithTranslation,
  i18n,
  useTranslation,
  Trans,
} = NextI18Next;

export const Link = NextLink;
export const Router = NextRouter;
export const useRouter = useNextRouter;

const i18Next = {
  appWithTranslation,
  i18n,
  useTranslation,
  Link,
  Router,
  Trans,
  useRouter,
};

// if your React component is used in component tests - make sure to use router from default export like this:
// import i18Next from '@/middleware/i18n';
// const router = i18Next.useRouter();
// Otherwise i18Next router will not be mocked correctly in tests.
export default i18Next;
