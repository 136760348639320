import { MobilePlatforms } from '@/controllers/app/app.typedefs';

const getNavigator = (): null | Navigator => {
  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  if (typeof window === 'undefined') {
    return null;
  }

  // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
  return window.navigator;
};

export const validateNavigator = (
  regex: RegExp,
): boolean => {
  const navigator = getNavigator();

  return (
    navigator
      ? (
        Boolean(navigator.userAgent)
      && regex.test(navigator.userAgent)
      )
      : false
  );
};

const isMobileRegex = /Mobile|Android|iP(hone|od|ad)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/i;
const isTabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;

export const isMobile = (): boolean => (
  validateNavigator(isMobileRegex)
);

export const isTablet = (): boolean => (
  validateNavigator(isTabletRegex)
);

export const getMobilePlatform = (): MobilePlatforms => {
  const navigator = getNavigator();

  if (!navigator) {
    return MobilePlatforms.Android;
  }

  return (
    Boolean(navigator.userAgent)
      && /iP(hone|od|ad)/i.test(navigator.userAgent)
      ? MobilePlatforms.iOS
      : MobilePlatforms.Android
  );
};
