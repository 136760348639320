import {
  oneOf,
  int,
} from '@/controllers/router/type-safe-routes/parsers';
import { PermissionsEntityRoutes } from '@/components/platform/Permissions/permissions.typedefs';

export const permissions = {
  path: ['permissions'],
  children: {
    tab: {
      path: [oneOf(...Object.values(PermissionsEntityRoutes)).optional('tab')],
      children: {
        entityId: {
          path: [int.optional('entityId')],
        },
      },
    },
  },
};
