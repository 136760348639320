import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '@/components/ui/icons/product/Mate/IconMateLogoFull.module.scss';

/**
 * Mate logo full icon
 * @deprecated Use LogoFull instead
 * @private This component is not for direct use
 */
export const IconMateLogoFull: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    {...rest}
    className={cn(className, styles.logoIcon)}
    width="168"
    height="56"
    viewBox="0 0 168 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="mate-logo-full-title">
      <path d="M85.9215 8.16666H82.7221L78.2159 15.6038L73.6871 8.16666H70.4877V23.8947H73.597V13.7164L78.0356 20.9962H78.3961L82.8347 13.6939V23.8947H85.9215V8.16666Z" />
      <path d="M96.8362 12.6604V13.986C96.025 12.9749 94.8084 12.3458 93.1636 12.3458C90.167 12.3458 87.6886 14.9297 87.6886 18.2775C87.6886 21.6254 90.167 24.2093 93.1636 24.2093C94.8084 24.2093 96.025 23.5801 96.8362 22.569V23.8947H99.7427V12.6604H96.8362ZM93.7043 21.4456C91.9244 21.4456 90.5951 20.1649 90.5951 18.2775C90.5951 16.3902 91.9244 15.1095 93.7043 15.1095C95.5068 15.1095 96.8362 16.3902 96.8362 18.2775C96.8362 20.1649 95.5068 21.4456 93.7043 21.4456Z" />
      <path d="M108.372 15.4465V12.6604H105.825V9.51477L102.919 10.3911V12.6604H100.959V15.4465H102.919V20.12C102.919 23.1532 104.293 24.3441 108.372 23.8947V21.2659C106.704 21.3557 105.825 21.3333 105.825 20.12V15.4465H108.372Z" />
      <path d="M112.074 19.4684H120.568C120.636 19.0864 120.681 18.7044 120.681 18.2775C120.681 14.9746 118.315 12.3458 114.98 12.3458C111.443 12.3458 109.032 14.9297 109.032 18.2775C109.032 21.6254 111.421 24.2093 115.206 24.2093C117.369 24.2093 119.059 23.333 120.118 21.8051L117.774 20.457C117.279 21.1086 116.377 21.5804 115.251 21.5804C113.719 21.5804 112.48 20.9513 112.074 19.4684ZM112.029 17.2215C112.367 15.7835 113.426 14.9522 114.98 14.9522C116.197 14.9522 117.414 15.6038 117.774 17.2215H112.029Z" />
      <path d="M78.9143 34.4269V35.7525C78.1032 34.7414 76.8865 34.1123 75.2418 34.1123C72.2451 34.1123 69.7667 36.6962 69.7667 40.044C69.7667 43.3918 72.2451 45.9757 75.2418 45.9757C76.8865 45.9757 78.1032 45.3466 78.9143 44.3355V45.6612H81.8208V34.4269H78.9143ZM75.7825 43.2121C74.0026 43.2121 72.6732 41.9314 72.6732 40.044C72.6732 38.1566 74.0026 36.8759 75.7825 36.8759C77.585 36.8759 78.9143 38.1566 78.9143 40.044C78.9143 41.9314 77.585 43.2121 75.7825 43.2121Z" />
      <path d="M89.3682 45.9757C91.5762 45.9757 93.4914 44.8074 94.4602 43.0548L91.9367 41.6168C91.4861 42.538 90.5173 43.0997 89.3456 43.0997C87.6108 43.0997 86.3265 41.819 86.3265 40.044C86.3265 38.2465 87.6108 36.9658 89.3456 36.9658C90.4947 36.9658 91.4636 37.55 91.9142 38.4712L94.4151 37.0107C93.4914 35.2807 91.5762 34.1123 89.3682 34.1123C85.9435 34.1123 83.42 36.6962 83.42 40.044C83.42 43.3918 85.9435 45.9757 89.3682 45.9757Z" />
      <path d="M103.933 34.4269V35.7525C103.121 34.7414 101.905 34.1123 100.26 34.1123C97.2634 34.1123 94.785 36.6962 94.785 40.044C94.785 43.3918 97.2634 45.9757 100.26 45.9757C101.905 45.9757 103.121 45.3466 103.933 44.3355V45.6612H106.839V34.4269H103.933ZM100.801 43.2121C99.0208 43.2121 97.6915 41.9314 97.6915 40.044C97.6915 38.1566 99.0208 36.8759 100.801 36.8759C102.603 36.8759 103.933 38.1566 103.933 40.044C103.933 41.9314 102.603 43.2121 100.801 43.2121Z" />
      <path d="M117.563 29.9331V35.7525C116.752 34.7189 115.558 34.1123 113.913 34.1123C110.894 34.1123 108.416 36.6962 108.416 40.044C108.416 43.3918 110.894 45.9757 113.913 45.9757C115.558 45.9757 116.752 45.3691 117.563 44.3355V45.6612H120.47V29.9331H117.563ZM114.454 43.2121C112.652 43.2121 111.322 41.9314 111.322 40.044C111.322 38.1566 112.652 36.8759 114.454 36.8759C116.234 36.8759 117.563 38.1566 117.563 40.044C117.563 41.9314 116.234 43.2121 114.454 43.2121Z" />
      <path d="M125.133 41.2348H133.627C133.695 40.8529 133.74 40.4709 133.74 40.044C133.74 36.7411 131.374 34.1123 128.04 34.1123C124.502 34.1123 122.091 36.6962 122.091 40.044C122.091 43.3918 124.48 45.9757 128.265 45.9757C130.428 45.9757 132.118 45.0994 133.177 43.5716L130.834 42.2235C130.338 42.8751 129.437 43.3469 128.31 43.3469C126.778 43.3469 125.539 42.7178 125.133 41.2348ZM125.088 38.988C125.426 37.55 126.485 36.7187 128.04 36.7187C129.256 36.7187 130.473 37.3702 130.834 38.988H125.088Z" />
      <path d="M147.778 34.1123C146.246 34.1123 145.119 34.7189 144.421 35.73C143.79 34.6965 142.754 34.1123 141.357 34.1123C139.915 34.1123 138.856 34.674 138.225 35.6177V34.4269H135.318V45.6612H138.225V39.3475C138.225 37.6623 139.104 36.8085 140.343 36.8085C141.56 36.8085 142.235 37.6174 142.235 38.943V45.6612H145.142V39.3475C145.142 37.6623 145.953 36.8085 147.237 36.8085C148.454 36.8085 149.13 37.6174 149.13 38.943V45.6612H152.036V38.7408C152.036 35.9322 150.347 34.1123 147.778 34.1123Z" />
      <path d="M161.157 34.4269L158.657 41.9763L155.727 34.4269H152.551L157.169 45.3017C156.629 46.8071 155.818 47.4811 154.218 47.4587V50.1549C157.079 50.3122 158.994 48.8966 160.166 45.6612L164.267 34.4269H161.157Z" />
    </g>
    <g id="mate-logo-full-fill">
      <path d="M34.1999 30.7143C34.1999 32.5 35.3983 33.619 37.0522 33.619C38.7061 33.619 39.9046 32.5 39.9046 30.7143C39.9046 28.9286 38.7061 27.8095 37.0522 27.8095C35.3983 27.8095 34.1999 28.9286 34.1999 30.7143Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M44 0C50.6274 0 56 5.37258 56 12V44C56 50.6274 50.6274 56 44 56H12C5.37258 56 0 50.6274 0 44V12C0 5.37258 5.37258 0 12 0H44ZM25.4435 20H29.2786V36.6667H25.4435V26.9762L21.105 34.0476H20.6736L16.3351 26.9762V36.6667H12.5V20H16.3351L20.8893 27.4048L25.4435 20ZM39.9046 25.881V24.7619H43.5V36.6667H39.9046V35.5476C39.1136 36.4524 37.9391 37 36.3331 37C33.1931 37 30.6044 34.2619 30.6044 30.7143C30.6044 27.1667 33.1931 24.4286 36.3331 24.4286C37.9391 24.4286 39.1136 24.9762 39.9046 25.881Z" />
    </g>
    <g id="mate-logo-full-text">
      <path d="M29.2786 20H25.4435L20.8893 27.4048L16.3351 20H12.5V36.6667H16.3351V26.9762L20.6736 34.0476H21.105L25.4435 26.9762V36.6667H29.2786V20Z" />
      <path d="M39.9046 24.7619V25.881C39.1136 24.9762 37.9391 24.4286 36.3331 24.4286C33.1931 24.4286 30.6044 27.1667 30.6044 30.7143C30.6044 34.2619 33.1931 37 36.3331 37C37.9391 37 39.1136 36.4524 39.9046 35.5476V36.6667H43.5V24.7619H39.9046ZM37.0522 33.619C35.3983 33.619 34.1999 32.5 34.1999 30.7143C34.1999 28.9286 35.3983 27.8095 37.0522 27.8095C38.7061 27.8095 39.9046 28.9286 39.9046 30.7143C39.9046 32.5 38.7061 33.619 37.0522 33.619Z" />
    </g>
  </BaseIcon>
);
