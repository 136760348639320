import {
  str,
} from '@/controllers/router/type-safe-routes/parsers';

export const payments = {
  path: ['payments'],
  children: {
    new: {
      path: ['new'],
    },
    tab: {
      path: [str('tab')],
      children: {
        historyTab: {
          path: [str.optional('historyTab')],
        },
      },
    },
  },
};
