import getClientLink from '@/lib/helpers/getClientLink';
import { Router } from '@/middleware/i18n';

export const changeLanguage = (options: {
  domain: string;
  language: string;
  redirectUrl?: string;
}): void => {
  const path = options.redirectUrl || Router.asPath;

  const isExternalLink = path.startsWith('http');

  const redirectLink = isExternalLink
    ? path
    : getClientLink(path, options);

  try {
    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    window.location.assign(redirectLink);
  } catch {
    /* do nothing */
  }
};
