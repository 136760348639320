import {
  oneOf,
  param,
  str,
  int,
} from 'typesafe-routes';
import { exists } from '@/lib/helpers/functional';

export const professionSlug = param({
  parse: (paramValue: string) => paramValue,
  serialize: (paramValue?: string) => {
    if (!paramValue) {
      return '';
    }

    return paramValue.replace('-parttime', '');
  },
});

export const intOrString = param<string | number>({
  parse: (paramValue: string) => parseInt(paramValue, 10),
  serialize: (paramValue?: string | number) => (
    exists(paramValue)
      ? String(paramValue)
      : ''
  ),
});

export {
  str,
  int,
  oneOf,
};
