import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  Feature,
  FeaturesData,
  FeaturesHandler,
  FeaturesQuery,
} from '@/controllers/features/features.typedefs';
import { Features } from '@/controllers/features/features.constants';
import { GetFeaturesDocument, GetFeaturesQuery } from '@/controllers/features/generated/getFeatures.query.generated';

const fetchAllFeatures = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<{
  features: Feature[];
}> => {
  const queryResult = await apolloClient.query<
    GetFeaturesQuery>(
      { query: GetFeaturesDocument },
    );

  if (queryResult.errors || !queryResult.data) {
    return { features: [] };
  }

  return { features: queryResult.data.allFeatures as Feature[] };
};

const toFeaturesObject = (features: Feature[]) => features
  .reduce((acc, cur) => {
    Object.assign(acc, {
      [cur.name]: cur.enabled,
    });

    return acc;
  }, {});

// handlers
export const createFeaturesHandler = (data: {
  featuresData: FeaturesData;
}): FeaturesHandler => ({
  isEnabled: (name: string) => {
    const { featuresData } = data;

    return featuresData[name] || false;
  },
  isDisabled: (name: string) => {
    const { featuresData } = data;

    return !featuresData[name];
  },
  areAllEnabled: (...names: string[]) => names.every(
    (name) => data.featuresData[name],
  ),
  areAllDisabled: (...names: string[]) => names.every(
    (name) => !data.featuresData[name],
  ),
  ...Features,
});

export const mapFeatures = (
  data: Pick<Feature, 'name' | 'enabled'>[],
  queryString: FeaturesQuery,
) => (
  data.reduce((acc, { name, enabled }) => {
    if (queryString[name]) {
      return {
        ...acc, [name]: queryString[name] === 'ENABLED',
      };
    }

    return { ...acc, [name]: enabled };
  }, {})
);

export const fetchMergedFeatures = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
): Promise<FeaturesData> => {
  const { features } = await fetchAllFeatures(apolloClient);

  return toFeaturesObject(features);
};
