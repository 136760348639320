export const payment = {
  path: ['payment'],
  children: {
    rate: {
      path: ['rate'],
      children: {
        success: {
          path: ['success'],
        },
        support: {
          path: ['support'],
        },
      },
    },
  },
};
