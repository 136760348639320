const {
  DEFAULT_LOCALE, DEFAULT_SUB_DOMAIN, DOMAIN_REGION, FALLBACK_LOCALE,
} = require('../i18n.config');

const i18nCreateLanguageSubpath = ({ domain, language }) => {
  const domainName = DOMAIN_REGION[domain] ?? domain;

  if (language === domainName) {
    return domainName;
  }

  // use 'uk' instead of 'uk-ua'
  if (language === DEFAULT_LOCALE && domainName === DEFAULT_SUB_DOMAIN) {
    return DEFAULT_LOCALE;
  }

  // use 'ru' instead of 'ru-ua'
  if (language === 'ru' && domainName === DEFAULT_SUB_DOMAIN) {
    return 'ru';
  }

  // use 'en' instead of 'en-ua'
  if (language === 'en' && domainName === DEFAULT_SUB_DOMAIN) {
    return FALLBACK_LOCALE;
  }

  // use 'usa' instead of 'en-usa'
  if (language === 'en' && domainName === 'usa') {
    return 'usa';
  }

  return `${language}-${domainName}`;
};

module.exports = {
  i18nCreateLanguageSubpath,
};
