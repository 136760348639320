import React, { memo } from 'react';
import { IconMateLogoCircle } from '@/components/ui/icons/product/Mate/IconMateLogoCircle';
import { FCIcon } from '@/components/ui/icons/typedefs';

/**
 * Mate logo circle icon
 * @deprecated Use LogoCircle instead
 * @private This component is not for direct use
 */
export const MateLogoCircle: FCIcon = memo((props) => (
  <IconMateLogoCircle {...props} />
));
