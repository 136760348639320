import { LogLevels } from '@mate-academy/logger';
import { isBrowser } from '@/middleware/helpers/isBrowser';
import { updateParticipantLastActionTime } from '@/components/platform/Chat/store.helpers';
import { MessageType } from '@/components/platform/Chat/chat.typedefs';
import { MentionCacheHelper } from '@/components/platform/Chat/cache.helpers/mention.helper';
import { UnreadMessagesCacheHelper } from '@/components/platform/Chat/cache.helpers/unreadMessages.helper';
import { errorHandler } from '@/core/ErrorHandler';
import { logger } from '@/core/Logger';
import { isValueInObject } from '@/lib/helpers/functional';
import { EMPTY_OBJECT } from '@/constants/common';
import { useSingleChatMessageReadWsSubscription } from '@/components/platform/Chat/graphql/generated/singleChatMessageReadWS.subscription.generated';

interface Options {
  skip?: boolean;
}

export const useSingleChatMessageReadWS = (options: Options = EMPTY_OBJECT) => {
  useSingleChatMessageReadWsSubscription({
    skip: !isBrowser || options.skip,
    onData: ({
      client,
      data: { data },
    }) => {
      const payload = data?.singleChatMessageReadWS;

      if (!payload || isValueInObject(payload)(undefined)) {
        errorHandler.captureMessage(
          'undefined payload occurred in useSingleChatMessageReadWS subscription',
          {
            logger: logger.child(useSingleChatMessageReadWS.name),
            logLevel: LogLevels.Warning,
          },
        );

        return;
      }

      const {
        message,
        participantId,
        hasTotalUnreadMessages,
        unreadChatMentionsCount,
      } = payload;

      switch (message.__typename) {
        case MessageType.Thread:
        case MessageType.OpenQuestion:
        case MessageType.Poll:
        case MessageType.SystemMessage: {
          const { chatId, createdAt } = message;

          updateParticipantLastActionTime({
            store: client.cache,
            participantId,
            lastActionTime: createdAt,
            chatId,
          });

          const mentionCacheHelper = new MentionCacheHelper(client.cache);
          const unreadsCacheHelper = new UnreadMessagesCacheHelper(client);

          unreadsCacheHelper.setHasUnreadMessagesFlag(hasTotalUnreadMessages);

          // Update the chat cache with the new unread mentions count
          mentionCacheHelper.setChatUnreadMentionsCount(
            chatId,
            unreadChatMentionsCount,
          );

          // Update the chat mentions counter query
          mentionCacheHelper.setChatMentionsCounter(
            chatId,
            unreadChatMentionsCount,
          );

          break;
        }

        default:
          break;
      }
    },
  });
};
