import React, { memo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { LogoSquareMap } from '@/components/common/Logo/constants';
import { Products } from '@/constants/products';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const LogoSquare: FCIcon<{
  withHoverEffect?: boolean;
}> = memo((props) => {
  const { productName } = useSubDomainContext();

  const Logo = LogoSquareMap[productName ?? Products.Mate];

  return <Logo {...props} />;
});
