import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { colorTokens } from '@/components/ui/colorTokens';

/**
 * Knowely logo fallback icon
 * @deprecated Use LogoFallback instead
 * @private This component is not for direct use
 */
export const IconKnowelyFallback: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.19054 2.56965C0 4.20829 0 6.54579 0 11.2208V12.7792C0 17.4542 0 19.7917 1.19054 21.4303C1.57504 21.9596 2.04044 22.425 2.56965 22.8095C4.20829 24 6.54579 24 11.2208 24H12.7792C17.4542 24 19.7917 24 21.4303 22.8095C21.9596 22.425 22.425 21.9596 22.8095 21.4303C24 19.7917 24 17.4542 24 12.7792V11.2208C24 6.54579 24 4.20829 22.8095 2.56965C22.425 2.04044 21.9596 1.57504 21.4303 1.19054C19.7917 0 17.4542 0 12.7792 0H11.2208C6.54579 0 4.20829 0 2.56965 1.19054C2.04044 1.57504 1.57504 2.04044 1.19054 2.56965ZM7.16676 20.4431C6.40627 20.1843 5.99955 19.3581 6.25833 18.5976L11.0669 4.46648C11.3257 3.70598 12.152 3.29926 12.9125 3.55805C13.673 3.81684 14.0797 4.64313 13.8209 5.40363L11.9286 10.9646L15.3404 8.12147C15.9576 7.6072 16.8747 7.69058 17.389 8.30771C17.9033 8.92484 17.8199 9.84202 17.2028 10.3563L14.18 12.8753L17.2028 15.3942C17.8199 15.9085 17.9033 16.8257 17.389 17.4428C16.8747 18.0599 15.9576 18.1433 15.3404 17.629L10.9156 13.9417L9.01234 19.5347C8.75356 20.2952 7.92726 20.7019 7.16676 20.4431Z"
      fill={colorTokens.icon}
    />
  </BaseIcon>
);
