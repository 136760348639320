import {
  InferPathParams,
  InferQueryParams,
  render,
  WithContext,
} from 'typesafe-routes';
import { IsEmptyObject } from '@/lib/helpers/utility-types';

export function renderRoute<R extends WithContext>(
  route: R,
  ...args: IsEmptyObject<InferQueryParams<R> & InferPathParams<R>> extends true
    ? []
    : [params: InferQueryParams<R> & InferPathParams<R>]
): string {
  const params = args[0] || {};

  return render(route, {
    path: params as InferQueryParams<R> & InferPathParams<R>,
    query: params as InferQueryParams<R> & InferPathParams<R>,
  });
}
