import React from 'react';
import { colorTokens } from '@/components/ui/colorTokens';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

/**
 * Mate logo icon
 * @deprecated Use LogoProduct instead
 * @private This component is not for direct use
 */
export const IconMateAcademy: FCIcon = (props) => {
  const fillColor = colorTokens['bg-neutral'];

  return (
    <BaseIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="12" fill="currentColor" />
      <path d="M12.548 8.35714H10.9044L8.95256 11.5306L7.00076 8.35714H5.35714V15.5H7.00076V11.3469L8.86011 14.3776H9.04502L10.9044 11.3469V15.5H12.548V8.35714Z" fill={fillColor} />
      <path d="M17.102 10.398V10.8776C16.763 10.4898 16.2596 10.2551 15.5713 10.2551C14.2256 10.2551 13.1162 11.4286 13.1162 12.949C13.1162 14.4694 14.2256 15.6429 15.5713 15.6429C16.2596 15.6429 16.763 15.4082 17.102 15.0204V15.5H18.6429V10.398H17.102ZM15.8795 14.1939C15.1707 14.1939 14.6571 13.7143 14.6571 12.949C14.6571 12.1837 15.1707 11.7041 15.8795 11.7041C16.5883 11.7041 17.102 12.1837 17.102 12.949C17.102 13.7143 16.5883 14.1939 15.8795 14.1939Z" fill={fillColor} />
    </BaseIcon>
  );
};
