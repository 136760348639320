export const subscriptions = {
  path: ['subscriptions'],
  children: {
    billing: {
      path: ['billing'],
    },
    manage: {
      path: ['manage'],
    },
    cancel: {
      path: ['cancel'],
    },
    active: {
      path: ['active'],
    },
    archive: {
      path: ['archive'],
    },
  },
};
