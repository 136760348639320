import { isBrowser } from '@/middleware/helpers/isBrowser';
import { ReactionCacheHelper } from '@/components/platform/Chat/cache.helpers/reaction.helper';
import {
  useReactionStackDeletedSubscription,
} from '@/components/platform/Chat/graphql/generated/reactionStackDeleted.subscription.generated';

export const useReactionStackDeleted = (authUserId?: number) => {
  useReactionStackDeletedSubscription({
    skip: !isBrowser || !authUserId,
    onData: ({ client, data }) => {
      const payload = data.data?.reactionStackDeleted;

      if (!payload) {
        return;
      }

      const cacheHelper = new ReactionCacheHelper(client.cache);

      cacheHelper.deleteStack(payload.id, payload.messageId);
    },
  });
};
