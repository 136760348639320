import React, { memo } from 'react';
import { IconKnowelyLogoFull } from '@/components/ui/icons/product/Knowely/IconKnowelyLogoFull';
import { FCIcon } from '@/components/ui/icons/typedefs';

/**
 * Knowely logo full icon
 * @deprecated Use LogoFull instead
 * @private This component is not for direct use
 */
export const KnowelyLogoFull: FCIcon = memo((props) => (
  <IconKnowelyLogoFull {...props} />
));
