import React, { memo } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { LogoFullMap } from '@/components/common/Logo/constants';
import { Products } from '@/constants/products';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const LogoFull: FCIcon = memo((props) => {
  const { productName } = useSubDomainContext();

  const Logo = LogoFullMap[productName ?? Products.Mate];

  return <Logo {...props} />;
});
