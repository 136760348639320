import { isBrowser } from '@/middleware/helpers/isBrowser';
import {
  useReactionStackCreatedSubscription,
} from '@/components/platform/Chat/graphql/generated/reactionStackCreated.subscription.generated';
import { ReactionCacheHelper } from '@/components/platform/Chat/cache.helpers/reaction.helper';

export const useReactionStackCreated = (authUserId?: number) => {
  useReactionStackCreatedSubscription({
    skip: !isBrowser || !authUserId,
    onData: ({ client, data }) => {
      const payload = data.data?.reactionStackCreated;

      if (!payload) {
        return;
      }

      const cacheHelper = new ReactionCacheHelper(client.cache);

      const isAlreadyReacted = cacheHelper.hasAuthUserReacted(payload.id);
      const isPayloadFromAuthUser = payload.senderUserId === authUserId;

      if (isAlreadyReacted && isPayloadFromAuthUser) {
        return;
      }

      cacheHelper.createStack({
        id: payload.id,
        messageId: payload.messageId,
        reactionShortcode: payload.reactionShortcode,
        authParticipantReacted: payload.senderUserId === authUserId,
        createdAt: payload.createdAt,
      });
    },
  });
};
