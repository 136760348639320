import differenceBy from 'lodash/differenceBy';
import isNull from 'lodash/isNull';
import { Identifiable, Truthy } from '@/typedefs/common';
import { MinimumArray } from '@/lib/helpers/utility-types';

interface Clamp {
  (val: number, min: number, max: number): number;
}

type PluckResult<T, K extends keyof T = keyof T> = (
  T extends null
    ? null
    : T[K]
);

export const id = <T>(x: T): T => x;

export const truthy = <T>(value: T): value is Truthy<T> => !!value;

export const emptyFunction: () => any = () => { /* EMPTY */ };

export const emptyAsyncFunction
  : () => Promise<any> = () => Promise.resolve();

export const nullFunction = () => null;

export const isStringEmpty = (value: string) => (
  value.trim().length === 0
);

export const isArrayEmpty = (array: any[]) => (
  array.length === 0
);

export const arrayHasLengthEqualOrMore = <T, Length extends number>(
  array: T[],
  length: Length,
): array is MinimumArray<Length, T> => array.length >= length;

export const clamp: Clamp = (val, min, max) => (
  Math.max(min, Math.min(val, max))
);

/**
 * Checks if the given value matches the specified Enum type.
 *
 * @template Enum - The Enum type to check against.
 * @template Key - The keys of the Enum type.
 *
 * @param {Enum} enumType - The Enum type to check against.
 * @returns {(value: string) => value is Enum[Key]} - A function that takes a string value and returns true if the value is of the Enum type, false otherwise.
 */
export const matchesEnum = <
  Enum extends { [key: string]: string },
  Key extends keyof Enum,
>(
    enumType: Enum,
  ): (value: string) => value is Enum[Key] => (
    value,
  ): value is Enum[Key] => (
    Object.values(enumType).includes(value)
  );

export const uniquifyById = <T extends Identifiable>(
  incoming: T[],
  existing: T[],
): T[] => ([
    ...existing,
    ...differenceBy(incoming, existing, (element) => (
      element.id
    )),
  ]);

export const excludeById = <T extends Identifiable>(
  source: T[],
  excludable: T[],
): T[] => (
    differenceBy(source, excludable, (element) => (
      element.id
    ))
  );

export const pluck = <T, K extends keyof T = keyof T>(key: K) => (
  (obj: T): PluckResult<T, K> => {
    if (isNull(obj)) {
      return null as PluckResult<T, K>;
    }

    return obj[key] as PluckResult<T, K>;
  }
);

export const arrangeIf = <T = any>(condition: boolean, ...items: T[]): T[] => (
  condition
    ? items
    : []
);

/**
 * Checks if the given value is not null or undefined.
 * @template T The type of the value to return if it exists.
 * @param {T | null | undefined} value The value to check.
 * @returns {boolean} True if the value is not null or undefined, false otherwise. Resolves value type to T if it exists, serves as a type guard.
 */
export function exists<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}

export const isValueInObject = (
  object: Record<string | number, unknown>,
) => (
  value: unknown,
): boolean => (
  Object.values(object).includes(value)
);

export const subtractWithFloatingPoint = (
  minuend: number,
  subtrahend: number,
  correctionFactor = 100,
): number => (
  Math.round((minuend - subtrahend) * correctionFactor) / correctionFactor
);

export const getRandomBoolean = (): boolean => (
  Math.random() >= 0.5
);
