import { oneOf } from '@/controllers/router/type-safe-routes/parsers';
import { DeleteAccountResult, EmailChangeResult } from '@/controllers/graphql/generated';

export const settings = {
  path: ['settings'],
  children: {
    changeEmail: {
      path: ['change-email', oneOf(...Object.values(EmailChangeResult)).optional('result')],
    },
    changePassword: {
      path: ['change-password'],
    },
    countryAndLanguage: {
      path: ['country-and-language'],
    },
    deleteAccount: {
      path: ['delete-account', oneOf(...Object.values(DeleteAccountResult)).optional('result')],
    },
    notificationPreferences: {
      path: ['notification-preferences'],
      children: {
        chats: {
          path: ['chats'],
        },
        editSchedule: {
          path: ['edit-schedule'],
        },
      },
    },
    setYourPassword: {
      path: ['set-your-password'],
    },
    personalData: {
      path: ['personal-data'],
    },
  },
};
