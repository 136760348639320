export const userDeviceInfo = (): {
  environment?: 'browser' | 'server';
  userAgent?: string;
} => {
  const result: {
    environment?: 'browser' | 'server';
    userAgent?: string;
  } = {};

  try {
    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    result.environment = typeof window !== 'undefined'
      ? 'browser'
      : 'server';
  } catch {
    // do nothing
  }

  try {
    // eslint-disable-next-line @mate-academy/frontend/restrict-window-usage
    result.userAgent = window.navigator.userAgent;
  } catch {
    // do nothing
  }

  return result;
};
