import {
  MateLogoFull,
  MateLogoSquare,
  MateLogoCircle,
} from '@/components/common/Logo/MateLogo';
import {
  KnowelyLogoFull,
  KnowelyLogoSquare,
  KnowelyLogoCircle,
} from '@/components/common/Logo/KnowelyLogo';
import {
  IconKnowely,
  IconKnowelyFallback,
} from '@/components/ui/icons/product/Knowely';
import {
  IconMateAcademy,
  IconMateFallback,
} from '@/components/ui/icons/product/Mate';

import { Products } from '@/constants/products';

export const LogoSquareMap = {
  [Products.Knowely]: KnowelyLogoSquare,
  [Products.Mate]: MateLogoSquare,
};

export const LogoFullMap = {
  [Products.Knowely]: KnowelyLogoFull,
  [Products.Mate]: MateLogoFull,
};

export const LogoCircleMap = {
  [Products.Knowely]: KnowelyLogoCircle,
  [Products.Mate]: MateLogoCircle,
};

export const LogoFallbackMap = {
  [Products.Knowely]: IconKnowelyFallback,
  [Products.Mate]: IconMateFallback,
};

export const LogoProductMap = {
  [Products.Knowely]: IconKnowely,
  [Products.Mate]: IconMateAcademy,
};
