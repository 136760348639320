export enum SubDomains {
  ua = 'ua',
  uk = 'uk',
  pl = 'pl',
  eu = 'eu',
  br = 'br',
  usa = 'usa',

  /**
   * Knowely subdomain
   */
  us = 'us',
}

export const SubDomainsWithoutFT = [
  SubDomains.br,
  SubDomains.pl,
];
