import React, { ComponentType } from 'react';
import { AppProps } from 'next/app';
import {
  GrowthBookProvider,
} from '@growthbook/growthbook-react';
import { setDisplayName } from '@/controllers/page/setDisplayName';
import {
  GrowthBookClient,
} from '@/components/services/GrowthBook/GrowthBook.client';
import type { AppGetInitialProps, MateAppProps } from '../../../../../pages/_app';

interface WithGrowthBookApp {
  (
      App: ComponentType<MateAppProps> & {
        getInitialProps?: AppGetInitialProps;
      }
  ): ComponentType<MateAppProps>;
}

export const withGrowthBookApp: WithGrowthBookApp = (App) => {
  const WithGrowthBookApp = (props: MateAppProps) => (
    <GrowthBookProvider
      growthbook={GrowthBookClient.getInstance().gb}
    >
      <App {...props} />
    </GrowthBookProvider>
  );

  const getInitialProps: AppGetInitialProps = async (ctx) => {
    let appProps: Partial<AppProps> = {};

    if (App.getInitialProps) {
      appProps = await App.getInitialProps(ctx);
    }

    return {
      ...appProps,
    };
  };

  WithGrowthBookApp.getInitialProps = getInitialProps;

  setDisplayName(App, WithGrowthBookApp, 'withGrowthBookApp');

  return WithGrowthBookApp;
};
