import { SubscriptionType } from '@/controllers/graphql/generated';

export const SUBSCRIPTION_PRODUCT_DOMAINS = ['us'];

export const SUBSCRIPTION_PRODUCT_DOMAINS_LIMITS = {
  maxFreeAutoTechChecksByWeek: 3,
  maxFreeDailyTasksToSolve: 5,
};

export const DISCOUNT_INSTEAD_TRIAL_SUBSCRIPTIONS = [
  SubscriptionType.SubscriptionProductAnnualWithDiscount,
];
