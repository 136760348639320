import { isBrowser } from '@/middleware/helpers/isBrowser';
import {
  useLeaderboardUserXpHistoryUpdatesSubscription,
} from '@/components/platform/Learn/graphql/generated/leaderboardUserXPHistoryUpdates.subscription.generated';
import {
  updateActivityEntityCache,
  updateLeaderboardCache,
} from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/leaderboard.helpers';
import {
  LEADERBOARD_USERS_LIST_UPDATED_KEY,
} from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/leaderboard.constants';
import { updateMonthStreaksQuery } from '@/components/platform/Home/XpChart/communication/hooks/updateMonthStreaksQuery';

interface Options {
  authUserId: number;
}

export const useXpHistoryUpdatesSubscription = ({
  authUserId,
}: Options) => {
  useLeaderboardUserXpHistoryUpdatesSubscription({
    skip: !isBrowser || !authUserId,
    variables: {
      userId: authUserId,
    },
    onData: ({ client, data }) => {
      if (!data.data?.leaderboardUserXPHistoryUpdates) {
        return;
      }

      const {
        leaderboardUser,
        leaderboardUserXpHistory,
      } = data.data?.leaderboardUserXPHistoryUpdates ?? {};

      if (leaderboardUserXpHistory) {
        updateActivityEntityCache(
          client,
          leaderboardUserXpHistory,
        );

        updateMonthStreaksQuery({
          client,
          authUserId,
          xpHistory: leaderboardUserXpHistory,
        });
      }

      if (leaderboardUser) {
        updateLeaderboardCache(
          client,
          leaderboardUser,
          authUserId,
        );

        localStorage.setItem(
          LEADERBOARD_USERS_LIST_UPDATED_KEY,
          JSON.stringify(true),
        );
      }
    },
  });
};
