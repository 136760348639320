import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '@/components/ui/icons/product/Knowely/IconKnowelyLogoSquare.module.scss';

/**
 * Knowely logo square icon
 * @deprecated Use LogoSquare instead
 * @private This component is not for direct use
 */
export const IconKnowelyLogoSquare: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    {...rest}
    className={cn(className, styles.logoIcon)}
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 30.8571C0 18.0009 0 11.5728 3.27399 7.06654C4.33136 5.6112 5.6112 4.33136 7.06654 3.27399C11.5728 0 18.0009 0 30.8571 0H35.1429C47.9991 0 54.4272 0 58.9335 3.27399C60.3888 4.33136 61.6686 5.6112 62.726 7.06654C66 11.5728 66 18.0009 66 30.8571V35.1429C66 47.9991 66 54.4272 62.726 58.9335C61.6686 60.3888 60.3888 61.6686 58.9335 62.726C54.4272 66 47.9991 66 35.1429 66H30.8571C18.0009 66 11.5728 66 7.06654 62.726C5.6112 61.6686 4.33136 60.3888 3.27399 58.9335C0 54.4272 0 47.9991 0 35.1429V30.8571Z" fill="#7852F4" />
    <path d="M17.2104 51.1433C16.4988 53.2346 17.6172 55.507 19.7086 56.2186C21.8 56.9303 24.0723 55.8118 24.7839 53.7204L30.0178 38.3396L42.1862 48.4798C43.8833 49.8941 46.4055 49.6648 47.8198 47.9677C49.234 46.2706 49.0047 43.7483 47.3076 42.3341L38.9951 35.4069L47.3076 28.4798C49.0047 27.0656 49.234 24.5433 47.8198 22.8462C46.4055 21.1491 43.8833 20.9198 42.1862 22.3341L32.8037 30.1528L38.0076 14.86C38.7193 12.7686 37.6008 10.4963 35.5094 9.78464C33.418 9.07298 31.1457 10.1915 30.4341 12.2828L17.2104 51.1433Z" fill="white" />
  </BaseIcon>
);
