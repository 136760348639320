import { isBrowser } from '@/middleware/helpers/isBrowser';
import {
  useChatMessagePinnedSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessagePinned.subscription.generated';
import { addToPinnedMessagesList } from '@/components/platform/Chat/store.helpers';

export const useChatMessagePinned = () => {
  useChatMessagePinnedSubscription({
    skip: !isBrowser,
    onData: ({
      client,
      data: { data },
    }) => {
      const { cache } = client;

      const pinnedMessage = data?.chatMessagePinned;

      if (!pinnedMessage) {
        return;
      }

      const messageApolloId = cache.identify(pinnedMessage);

      const { pinnedBy } = pinnedMessage;

      cache.modify({
        id: messageApolloId,
        fields: {
          ...(pinnedBy && { pinnedBy: () => pinnedBy }),
        },
      });

      addToPinnedMessagesList(client, pinnedMessage);
    },
  });
};
