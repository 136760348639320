import React from 'react';
import { cn } from '@/lib/classNames';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import styles from '@/components/ui/icons/product/Knowely/IconKnowelyLogoCircle.module.scss';

/**
 * Knowely logo circle icon
 * @deprecated Use LogoCircle instead
 * @private This component is not for direct use
 */
export const IconKnowelyLogoCircle: FCIcon = ({ className, ...rest }) => (
  <BaseIcon
    {...rest}
    className={cn(className, styles.logoIcon)}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#7852F4" />
    <path
      d="M16.6026 49.5935C15.9161 51.6215 16.995 53.8249 19.0126 54.515C21.0301 55.2051 23.2222 54.1206 23.9087 52.0926L28.9578 37.1778L40.6965 47.0108C42.3337 48.3822 44.7669 48.1598 46.1312 46.5141C47.4956 44.8684 47.2744 42.4226 45.6372 41.0512L37.6181 34.334L45.6372 27.6168C47.2744 26.2454 47.4956 23.7996 46.1312 22.1539C44.7669 20.5082 42.3337 20.2859 40.6965 21.6573L31.6453 29.2391L36.6655 14.4097C37.352 12.3817 36.273 10.1782 34.2555 9.48815C32.238 8.79805 30.0459 9.88263 29.3594 11.9106L16.6026 49.5935Z"
      fill="white"
    />
  </BaseIcon>
);
