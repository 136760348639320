import { MessageType } from '@/components/platform/Chat/chat.typedefs';
import { deleteThreadFromQuery } from '@/components/platform/Chat/cache.helpers/chatMessagesQuery.helper';
import { deleteMessageFromThread } from '@/components/platform/Chat/store.helpers';
import { isBrowser } from '@/middleware/helpers/isBrowser';
import {
  useChatMessageDeletedWsSubscription,
} from '@/components/platform/Chat/graphql/generated/chatMessageDeletedWS.subscription.generated';

interface Params {
  skip?: boolean;
}

export const useChatMessageDeletedWS = ({ skip }: Params = {}) => {
  useChatMessageDeletedWsSubscription({
    skip: !isBrowser || skip,
    onData: ({
      client: { cache },
      data: { data },
    }) => {
      const deletedMessage = data?.chatMessageDeletedWS.deletedMessage;

      if (!deletedMessage) {
        return;
      }

      switch (deletedMessage.type) {
        case MessageType.Thread:
        case MessageType.OpenQuestion:
        case MessageType.Poll: {
          deleteThreadFromQuery(cache, deletedMessage);

          break;
        }

        case MessageType.Message: {
          deleteMessageFromThread({
            store: cache,
            parentType: MessageType.Thread,
            message: deletedMessage,
          });

          break;
        }

        case MessageType.OpenQuestionAnswer: {
          deleteMessageFromThread({
            store: cache,
            parentType: MessageType.OpenQuestion,
            message: deletedMessage,
          });

          break;
        }

        default:
          break;
      }
    },
  });
};
