import { useMemo } from 'react';
import {
  useSoundNotificationSentSubscription,
} from '@/components/platform/Chat/graphql/generated/soundNotificationSent.subscription.generated';
import { usePlayNotificationSound } from '@/hooks/usePlayNotificationSound';
import { isBrowser } from '@/middleware/helpers/isBrowser';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';

export const useSoundNotificationSent = () => {
  const { playNotificationSound } = usePlayNotificationSound();
  const { isEnabled } = useFeatures();

  const isSoundNotificationsEnabled = useMemo(() => (
    isEnabled(Features.ChatSoundNotifications)
  ), [isEnabled]);

  useSoundNotificationSentSubscription({
    skip: !isBrowser || !isSoundNotificationsEnabled,
    onData: () => {
      playNotificationSound();
    },
  });
};
