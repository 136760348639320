import { isBrowser } from '@/middleware/helpers/isBrowser';
import { ReactionCacheHelper } from '@/components/platform/Chat/cache.helpers/reaction.helper';
import {
  useReactionAddedToStackSubscription,
} from '@/components/platform/Chat/graphql/generated/reactionAddedToStack.subscription.generated';

export const useReactionAddedToStack = (authUserId?: number) => {
  useReactionAddedToStackSubscription({
    skip: !isBrowser || !authUserId,
    onData: ({ client, data }) => {
      const payload = data.data?.reactionAddedToStack;

      if (!payload) {
        return;
      }

      const cacheHelper = new ReactionCacheHelper(client.cache);

      const isPayloadFromAuthUser = payload.senderUserId === authUserId;
      const isAlreadyReacted = cacheHelper.hasAuthUserReacted(payload.id);

      if (isAlreadyReacted && isPayloadFromAuthUser) {
        return;
      }

      cacheHelper.incrementStackReactionsCount(payload.id, {
        isAuthUser: payload.senderUserId === authUserId,
      });
    },
  });
};
