interface ComposeFn<Input, Output> {
  (input: Input): Output;
}

export const compose = <TFinal, TInitial>(
  fn1: ComposeFn<TInitial, TFinal>,
  ...fns: Array<ComposeFn<any, any>>
): ComposeFn<any, TFinal> => fns.reduce(
    (prevFn, nextFn) => (value) => prevFn(nextFn(value)),
    fn1,
  );
