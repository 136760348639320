import React, { ComponentType } from 'react';
import { AppProps } from 'next/app';
import {
  fetchMergedFeatures,
  createFeaturesHandler,
} from '@/controllers/features/features.helpers';
import { PageProps } from '@/controllers/page/page.typedefs';
import { setDisplayName } from '@/controllers/page/setDisplayName';
import type { AppGetInitialProps, MateAppProps } from '../../../pages/_app';

interface WithFeaturesApp {
  (
    App: ComponentType<MateAppProps> & { getInitialProps?: AppGetInitialProps }
  ): ComponentType<MateAppProps>;
}

export const withFeaturesApp: WithFeaturesApp = (App) => {
  const WithFeaturesApp = (props: AppProps<PageProps>) => (
    <App {...props} />
  );

  setDisplayName(App, WithFeaturesApp, 'withFeaturesApp');

  WithFeaturesApp.getInitialProps = async (ctx: any) => {
    const { ctx: { apolloClient } } = ctx;

    let initialProps = {};

    const featuresData = await fetchMergedFeatures(apolloClient);

    Object.assign(ctx.ctx, {
      features: createFeaturesHandler({ featuresData }),
    });

    if (App.getInitialProps) {
      initialProps = await App.getInitialProps(ctx);
    }

    return {
      ...initialProps,
      featuresData,
    };
  };

  return WithFeaturesApp;
};
