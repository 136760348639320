import { useCallback, useMemo } from 'react';
import { isBrowser } from '@/middleware/helpers/isBrowser';

export const usePlayNotificationSound = () => {
  const notificationSound = useMemo(() => {
    if (!isBrowser) {
      return null;
    }

    const sound = new Audio('/static/sounds/notification.mp3');

    sound.volume = 0.5;
    sound.preload = 'auto';

    return sound;
  }, []);

  const playNotificationSound = useCallback(async () => {
    if (!notificationSound) {
      return;
    }

    if (notificationSound.readyState >= HTMLMediaElement.HAVE_ENOUGH_DATA) {
      try {
        notificationSound.currentTime = 0;
        await notificationSound.play();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error playing sound:', e);
      }
    }
  }, [notificationSound]);

  return { playNotificationSound };
};
