export enum I18N_CODES {
  common = 'all',
  checkIn = 'check_in',
  error = 'error',
  home = 'home',
  platformHome = 'platform_home',
  course = 'course',
  coursePortfolio = 'course_portfolio',
  courseProgram = 'course_program',
  courseCover = 'course_cover',
  employmentSteps = 'employment_steps',
  login = 'login',
  loginPage = 'login_page',
  register = 'register',
  resetPassword = 'reset_password',
  forgotPasswordMessage = 'forgot_password_message',
  team = 'our_team',
  payment = 'payment',
  price = 'price',
  pricingCards = 'pricing_cards',
  profile = 'profile',
  platformSidebar = 'sidebar',
  seo = 'seo',
  validates = 'validates',
  faq = 'faq',
  cities = 'cities',
  regions = 'regions',
  applications = 'applications',
  settings = 'settings',
  notifications = 'notifications',
  vacancies = 'vacancies',
  aboutUs = 'about_us',
  support = 'support',
  english = 'english',
  quote = 'quote',
  reviews = 'reviews',
  companies = 'companies',
  cta = 'cta',
  howItWorks = 'how_it_works',
  technologies = 'technologies',
  codeEditor = 'code_editor',
  courseSchedule = 'course_schedule',
  learn = 'learn',
  wallet = 'wallet',
  studyingWays = 'studying_ways',
  checkout = 'checkout',
  courseAuthor = 'course_author',
  github = 'github',
  subscriptions = 'subscriptions',
  likeRating = 'like_rating',
  referral = 'referral',
  onboarding = 'onboarding',
  guidedOnboarding = 'guided_onboarding',
  priceIncludes = 'price_includes',
  events = 'events',
  quizUI = 'quiz-ui',
  webinar = 'webinar',
  loan = 'loan',
  preparation = 'preparation',
  englishDashboard = 'english_dashboard',
  techCheck = 'tech_check',
  schedule = 'schedule',
  slackSender = 'slack_sender',
  paymentsFaqUI = 'payments-faq-ui',
  partners = 'partners',
  englishCourse = 'english_course',
  courseConsultation = 'course_consultation',
  countrySelector = 'country_selector',
  lmsEditor = 'lms_editor',
  guidelines = 'guidelines',
  chat = 'chat',
  hire = 'hire',
  hirePlatform = 'hire_platform',
  learningSteps = 'learning_steps',
  quiz = 'quiz',
  permissions = 'permissions',
  homeworkReviews = 'homework_reviews',
  type = 'type',
  competitiveAnalysis = 'competitive_analysis',
  changeTrack = 'change_track',
  admissionProgress = 'admission_progress',
  aiPlayground = 'ai_playground',
  autoTechChecks = 'auto_tech_checks',
  group = 'group',
  contractorsManagement = 'contractors_management',
  registration = 'registration',
  achievements = 'achievements',
  platformNotifications = 'platform_notifications',
  leaderboardFaq = 'leaderboard-faq',
  softDeadline = 'soft_deadline',
  platformDemo = 'platform_demo',
  signUpSurvey = 'sign_up_survey',
  aiAssistant = 'ai_assistant',
  studySchedule = 'study_schedule',
  matchWorkingTime = 'match_working_time',
  globalSearch = 'global_search',
  examsManagement = 'exams_management',
  promoCode = 'promo_code',
  certificate = 'certificate',
  leaderboard = 'leaderboard',
  streak = 'streak',
  employmentGuide = 'employment_guide',
}

export enum AppEnvironments {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
  Test = 'test',
  Local = 'local',
}

export enum NodeEnvironments {
  Development = 'development',
  Production = 'production',
  Test = 'test',
}
